




















































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";

@Component({
  components: {
    Header
  }
})
export default class Login extends Vue {
  loginForm: Parms = {
    name: "",
    code: ""
  };
  isCode = true;
  btnTxt = "发送验证码";

  get loginStateTxt() {
    return this.isCode ? "密码" : "验证码";
  }

  get codePlaceholder() {
    return this.isCode ? "验证码" : "密码";
  }

  changeLoginMode() {
    this.isCode = !this.isCode;
  }

  sendCode() {
    if (this.loginForm.name.length !== 11 || this.btnTxt !== "发送验证码") {
      return;
    }
    let time = 60;
    const timer = setInterval(() => {
      time--;
      this.btnTxt = `${time}秒后重试`;

      if (time == 0) {
        clearInterval(timer);
        this.btnTxt = `发送验证码`;
      }
    }, 1000);
  }
}
